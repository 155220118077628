import localStorageConstants from 'constants/localStorage';
import localizationConstants from 'constants/localization';

const { LOCALIZATION } = localStorageConstants;
const { REGIONS } = localizationConstants;

const getCurrentLanguage = () => {
  return localStorage.getItem(LOCALIZATION) || REGIONS.vi.key;
};

const changeLanguage = (language: string) => {
  if (language === getCurrentLanguage()) return;
  localStorage.setItem(LOCALIZATION, language);
  window.location.reload();
};

export default {
  changeLanguage,
  getCurrentLanguage,
};
