import { IBaseGetResponse, IGetDataResponse } from './common';

export enum EPaymentRedirectType {
  Cancel = 'cancel',
  Success = 'success',
}
export enum EPaymentAIOCardType {
  REDIRECT_URL_THANH_TOAN = 1,
  GEN_QR_CONG,
}

export interface IPaymentEnvConfig {
  merchantCode: string;
  terminalCode: string;
  initSecretKey: string;
}

export interface IPaymentMethod {
  partnerCode: string;
  terminalCode: string;
  methodCode: string;
  displayName: string;
  displayText: string;
  displayIcon: string;
  methodGroupCode: string;
  merchantMethodCode: string;
  token: string;
  discountCodes: string[];
  templateType: string;
  cards: [
    {
      displayName: string;
      displayText: string;
      displayAppIcon: string;
      bankName: string;
      token: string;
    }
  ];
}
export interface IPaymentMethodParams {
  userId?: string;
  merchantCode: string;
  terminalCode: string;
}
export interface IPaymentMethodGetData {
  merchantCode: string;
  paymentMethods: IPaymentMethod[];
}
export type IPaymentMethodGetResult = IGetDataResponse<IPaymentMethodGetData>;

export interface IPaymentAIOQR {
  amount: number;
  methodCode: string;
  merchantMethodCode: string;
  clientTransactionCode: string;
}
export interface IPaymentAIOCard {
  amount: number;
  bankCode?: string;
  methodCode: string;
  type: EPaymentAIOCardType;
  merchantMethodCode: string;
  clientTransactionCode: string;
}
export interface IPaymentAIOCreatePayload {
  userId: string;
  checksum: string;
  orderCode: string;
  cancelUrl: string;
  successUrl: string;
  merchantCode: string;
  terminalCode: string;
  expiredDate?: string;
  totalPaymentAmount: number;
  totalPaymentWithoutFee: number;
  payments: {
    qr?: IPaymentAIOQR;
    card?: IPaymentAIOCard;
  };
}
export interface IPaymentCreateResult {
  amount: string;
  traceId: string;
  returnUrl: string;
  methodCode: string;
  responseCode: string;
  responseMessage: string;
  transactionCode: string;
  clientTransactionCode: string;
}
export type IPaymentAIOCreateResult = IBaseGetResponse<{
  code: string;
  message: string;
  orderCode: string;
  paymentRequestId: string;
  payments: {
    qr: IPaymentCreateResult;
    card: IPaymentCreateResult;
  };
}>;
