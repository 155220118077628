import React from 'react';
import { Layout } from 'antd';
import routes from 'routes';
import AppContent from './AppContent';
import './AppLayout.scss';

/**
 * This container is for lifting-up the `AppContent` to the parent node,
 * so we can avoid unnecessary re-calculation when resizing window
 * */

const AppLayout: React.FC<any> = () => {
  return (
    <Layout className="app-layout">
      <AppContent filteredRoutes={routes} />
    </Layout>
  );
};

export default AppLayout;
