import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { locale } from 'helpers/i18n';
import { EDatetimeFormat } from 'interfaces';
import { datetimeConstants } from 'constants/index';

dayjs.locale(locale);
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(customParseFormat);

const { DATETIME_FORMAT } = datetimeConstants;

const timeZToDayjs = (value: string): Dayjs => {
  const tzOffset = new Date().getTimezoneOffset();
  return dayjs(value).utcOffset(tzOffset, true);
};
const timeZToStartOfDayDayjs = (value: string): Dayjs => {
  return dayjs.utc(value).startOf('day');
};
const formatDatetime = (value: Dayjs, format?: string): string => {
  return value.format(format);
};
const formatDatetimeZ = (timeZ?: string, format?: string): string => {
  return format && timeZ
    ? formatDatetime(timeZToDayjs(timeZ), format)
    : timeZ || '';
};
const subtractDatetime = (
  date: Dayjs,
  value?: number,
  unit?: ManipulateType
): Dayjs => {
  return value && unit ? date.subtract(value, unit) : date;
};
const getFormatByLocale = (
  locale: string,
  format: EDatetimeFormat
): string | undefined => {
  return DATETIME_FORMAT[format] && DATETIME_FORMAT[format][locale];
};

export default {
  dayjs,
  timeZToDayjs,
  timeZToStartOfDayDayjs,
  formatDatetime,
  formatDatetimeZ,
  subtractDatetime,
  getFormatByLocale,
};
