import { IGetDataResponse } from './common';

export enum EPlanBenefitTimeUnit {
  DAY = 1,
  MONTH,
  YEAR,
}

export interface IPlan {
  licensePlanId: number;
  name: string;
  price: number;
  benefitTime: number;
  benefitTimeUnit: EPlanBenefitTimeUnit;
}

export type IPlanResult = IGetDataResponse<IPlan[]>;
