import { AxiosError } from 'axios';
import { notification } from 'antd';
import { t } from 'helpers/i18n';
import { IHeaderErrorConfig } from 'interfaces';

const handleResponseError = (error: AxiosError) => {
  const response = error && error.response;
  const status = response && response.status;
  const data = response && response.data;
  const headers = error.config.headers;
  const errorConfig: IHeaderErrorConfig | undefined = headers.errorConfig;
  // const statusConfig: IErrorConfig =
  //   (errorConfig && status && errorConfig[status]) || {};
  switch (status) {
    // case 401:
    //   // if (statusConfig.launchAction !== false) userServices.logout();
    //   break;
    // case 403:
    //   // if (statusConfig.launchAction !== false) userServices.denyAccess();
    //   break;
    default:
      if (!errorConfig || errorConfig.showNotification !== false) {
        const { code: responseCode } = data || {};
        const { code: errorCode } = errorConfig || {};
        const message = data && data.message;
        const errorCodeConfig = (errorCode || {})[responseCode];

        notification.error({
          message:
            errorConfig?.message || errorCodeConfig?.message || t('Error'),
          description:
            errorConfig?.description ||
            errorCodeConfig?.description ||
            message ||
            t('SomethingWentWrong'),
        });
      }
      break;
  }
};

export default {
  handleResponseError,
};
