import { EDatetimeFormat, IDatetimeFormat } from 'interfaces';

const DATETIME_FORMAT: IDatetimeFormat = {
  [EDatetimeFormat.DATETIME_SECONDS]: {
    vi: 'HH:mm:ss DD/MM/YYYY',
    en: 'HH:mm:ss MM/DD/YYYY',
  },
  [EDatetimeFormat.DATETIME]: {
    vi: 'HH:mm DD/MM/YYYY',
    en: 'HH:mm MM/DD/YYYY',
  },
  [EDatetimeFormat.DATE]: {
    vi: 'DD/MM/YYYY',
    en: 'MM/DD/YYYY',
  },
  [EDatetimeFormat.TIME_SECONDS]: {
    vi: 'HH:mm:ss',
    en: 'HH:mm:ss',
  },
  [EDatetimeFormat.TIME]: {
    vi: 'HH:mm',
    en: 'HH:mm',
  },
};

export default {
  DATETIME_FORMAT,
};
