const formatCurrency = (value?: number, currency = 'VND'): string => {
  return (
    (value &&
      value.toLocaleString('vi', {
        style: 'currency',
        currency,
      })) ||
    ''
  );
};

export default {
  formatCurrency,
};
