import { lazy } from 'react';

const ActiveLicense = lazy(() => import('containers/ActiveLicense'));
const ActiveLicenseResult = lazy(() =>
  import('containers/ActiveLicense/ActiveLicenseResult')
);

const routes = [
  {
    path: '/',
    exact: true,
    component: ActiveLicense,
  },
  {
    path: '/active-license',
    exact: true,
    component: ActiveLicenseResult,
  },
];

export default routes;
