const isUndefined = (value: any) => {
  return value === undefined;
};

const isNull = (value: any) => {
  return value === null;
};

const isEmpty = (value: any) => {
  return isNull(value) || isUndefined(value) || value === '';
};

const isObject = (value: any) => {
  return (
    !(isNull(value) || isUndefined(value)) &&
    typeof value === 'object' &&
    !Array.isArray(value)
  );
};

const isArray = (value: any) => {
  return (
    !(isNull(value) || isUndefined(value)) &&
    typeof value === 'object' &&
    Array.isArray(value)
  );
};

const isArrayEmpty = (value: any) => {
  return isEmpty(value) || !value.some((val: any) => !!val);
};

const isObjectEmpty = (value: any) => {
  return isEmpty(value) || !Object.entries(value).some(([_, val]) => !!val);
};

const isEmptyStrict = (value: any) => {
  return (
    isEmpty(value) ||
    (isObject(value) && isObjectEmpty(value)) ||
    (isArray(value) && isArrayEmpty(value))
  );
};

export default {
  isNull,
  isArray,
  isEmpty,
  isObject,
  isUndefined,
  isEmptyStrict,
  isArrayEmpty,
  isObjectEmpty,
};
