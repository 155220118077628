import { IBranch } from './branch';
import { IGetDataResponse } from './common';

export enum ERetailerAccountType {
  New = 0,
  FreeTrial = 1,
  Cancel = 2,
  Premium = 3,
}

export interface IRetailer {
  retailerId: number;
  retailerUrl: string;
  retailerName: string;
  accountType: ERetailerAccountType;
  branches: IBranch[];
}

export type IRetailerResult = IGetDataResponse<IRetailer>;
