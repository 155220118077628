import React from 'react';

const getWindowDimensions = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

const isHTML = (str: string) => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
};

export const isScrollEnded = (event: React.UIEvent<HTMLDivElement>) => {
  const { scrollTop, offsetHeight, scrollHeight } = event.target as HTMLElement; // TODO: remove bypass
  return Math.abs(scrollTop + offsetHeight - scrollHeight) <= 1;
};

export default {
  isHTML,
  isScrollEnded,
  getWindowDimensions,
};
