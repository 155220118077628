import React from 'react';
import ReactTracker from 'react-tracker-teko';
import { Router, Switch, Route } from 'react-router-dom';
import { browserHistory } from 'helpers';
import './App.less';
import './App.scss';
import AppLayout from 'containers/AppLayout';
// import Page403 from 'containers/shared/Page403';
// import Page404 from 'containers/shared/Page404';
// import Page500 from 'containers/shared/Page500';

const { tracker } = window.config;

const reactTracker = new ReactTracker({
  // Configure your tracker server and site by providing
  host: (tracker.appId && tracker.host) || '',
  urlServeJsFile: (tracker.appId && tracker.jsFile) || '',
  appId: tracker.appId || '',
});

const App: React.FC = () => {
  return (
    <Router history={reactTracker.connectToHistory(browserHistory)}>
      <Switch>
        {/*<Route exact path="/403" component={Page403} />*/}
        {/*<Route exact path="/404" component={Page404} />*/}
        {/*<Route exact path="/500" component={Page500} />*/}
        <Route render={routeProps => <AppLayout {...routeProps} />} />
      </Switch>
    </Router>
  );
};

export default App;
