export enum EDatetimeFormat {
  DATETIME_SECONDS,
  DATETIME,
  DATE,
  TIME_SECONDS,
  TIME,
}

export type IDatetimeFormat = {
  [key in EDatetimeFormat]: {
    [locale: string]: string;
  };
};
