import { IGetDataResponse } from './common';

export enum EOrderType {
  Active = 1,
  ExtendTime = 2,
  AddBranch = 3,
}
export enum EOrderStatus {
  Create,
  Success,
  Failed,
  Canceled,
  Timeout,
}

export interface IActiveLicenseOrder {
  orderId: number;
  createdAt: string;
  branchName: string;
  expiryDate: string;
  retailerUrl: string;
  retailerName: string;
  totalAmount: number;
  orderType: EOrderType;
  orderStatus: EOrderStatus;
  transactionCode: string;
  merchantTransactionCode: string;
}
export type IActiveLicenseOrderResult = IGetDataResponse<IActiveLicenseOrder>;

export interface IActiveLicenseOrderCreatePayload {
  retailerId?: number;
  orderType?: EOrderType;
  licensePlanId?: number;
  branchId?: number;
  branchName?: string;
  orderId?: number;
  transactionCode?: string;
}
export interface IActiveLicenseOrderCreateData {
  orderId: number;
  orderCode: string;
  totalAmount: number;
  merchantTransactionCode: string;
}
export type IActiveLicenseOrderCreateResult = IGetDataResponse<
  IActiveLicenseOrderCreateData
>;
